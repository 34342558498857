//import logo from './logo.svg';
import './App.css';
import Lightroom from 'react-lightbox-gallery'

function App() {

  var images = [
    {
      src: "assets/images/image-1.jpeg",
      desc: '',
      sub: ''
    },
    {
      src: "assets/images/image-2.jpeg",
      desc: '',
      sub: ''
    },
    {
      src: "assets/images/image-3.jpeg",
      desc: '',
      sub: ''
    },
    {
      src: "assets/images/Image 2022-05-08 at 11.23.33 AM.jpeg",
      desc: '',
      sub: ''
    },
    {
      src: "assets/images/Image 2022-05-08 at 12.04.13 PM.jpeg",
      desc: '',
      sub: ''
    },
    {
      src: "assets/images/Image 2022-05-08 at 12.02.46 PM.jpeg",
      desc: '',
      sub: ''
    },
    {
      src: "assets/images/Image 2022-05-08 at 12.02.05 PM.jpeg",
      desc: '',
      sub: ''
    },
    {
      src: "assets/images/Image 2022-05-08 at 12.02.04 PM.jpeg",
      desc: '',
      sub: ''
    },
    {
      src: "assets/images/Image 2022-05-08 at 12.01.37 PM-2.jpeg",
      desc: '',
      sub: ''
    },
    {
      src: "assets/images/Image 2022-05-08 at 12.01.37 PM.jpeg",
      desc: '',
      sub: ''
    },
    {
      src: "assets/images/Image 2022-05-08 at 12.01.36 PM.jpeg",
      desc: '',
      sub: ''
    },
    {
      src: "assets/images/Image 2022-05-08 at 12.01.35 PM.jpeg",
      desc: '',
      sub: ''
    },
    {
      src: "assets/images/Image 2022-05-08 at 12.00.37 PM.jpeg",
      desc: '',
      sub: ''
    },
    {
      src: "assets/images/Image 2022-05-08 at 11.59.38 AM.jpeg",
      desc: '',
      sub: ''
    },
    {
      src: "assets/images/Image 2022-05-08 at 11.59.37 AM-2.jpeg",
      desc: '',
      sub: ''
    },
    {
      src: "assets/images/Image 2022-05-08 at 11.59.37 AM.jpeg",
      desc: '',
      sub: ''
    },
    {
      src: "assets/images/Image 2022-05-08 at 11.59.36 AM.jpeg",
      desc: '',
      sub: ''
    },
    {
      src: "assets/images/Image 2022-05-08 at 11.59.35 AM.jpeg",
      desc: '',
      sub: ''
    },
    {
      src: "assets/images/Image 2022-05-08 at 11.59.26 AM.jpeg",
      desc: '',
      sub: ''
    },
    
    {
      src: "assets/images/Image 2022-05-08 at 11.57.46 AM.jpeg",
      desc: '',
      sub: ''
    },
    {
      src: "assets/images/Image 2022-05-08 at 11.57.15 AM.jpeg",
      desc: '',
      sub: ''
    },
    {
      src: "assets/images/Image 2022-05-08 at 11.57.18 AM.jpeg",
      desc: '',
      sub: ''
    },
    {
      src: "assets/images/Image 2022-05-08 at 11.57.19 AM.jpeg",
      desc: '',
      sub: ''
    },
    {
      src: "assets/images/Image 2022-05-08 at 11.57.19 AM-2.jpeg",
      desc: '',
      sub: ''
    },
    {
      src: "assets/images/Image 2022-05-08 at 11.56.15 AM.jpeg",
      desc: '',
      sub: ''
    },
    {
      src: "assets/images/Image 2022-05-08 at 11.55.57 AM.jpeg",
      desc: '',
      sub: ''
    },

    {
      src: "assets/images/Image 2022-05-08 at 11.52.15 AM.jpeg",
      desc: '',
      sub: ''
    },
    {
      src: "assets/images/Image 2022-05-08 at 11.52.15 AM-2.jpeg",
      desc: '',
      sub: ''
    },
    {
      src: "assets/images/Image 2022-05-08 at 11.52.14 AM-2.jpeg",
      desc: '',
      sub: ''
    },
    {
      src: "assets/images/Image 2022-05-08 at 11.52.14 AM.jpeg",
      desc: '',
      sub: ''
    },
    {
      src: "assets/images/Image 2022-05-08 at 11.51.28 AM.jpeg",
      desc: '',
      sub: ''
    },
    {
      src: "assets/images/Image 2022-05-08 at 11.51.28 AM-2.jpeg",
      desc: '',
      sub: ''
    },

    {
      src: "assets/images/Image 2022-05-08 at 11.50.24 AM-2.jpeg",
      desc: '',
      sub: ''
    },
    {
      src: "assets/images/Image 2022-05-08 at 11.50.24 AM.jpeg",
      desc: '',
      sub: ''
    },
    
    {
      src: "assets/images/Image 2022-05-08 at 11.50.23 AM-2.jpeg",
      desc: '',
      sub: ''
    },
    {
      src: "assets/images/Image 2022-05-08 at 11.50.23 AM.jpeg",
      desc: '',
      sub: ''
    },
       
    {
      src: "assets/images/Image 2022-05-08 at 11.50.22 AM-2.jpeg",
      desc: '',
      sub: ''
    },
    {
      src: "assets/images/Image 2022-05-08 at 11.50.22 AM.jpeg",
      desc: '',
      sub: ''
    },
    
    {
      src: "assets/images/Image 2022-05-08 at 11.50.21 AM.jpeg",
      desc: '',
      sub: ''
    },
      
    {
      src: "assets/images/Image 2022-05-08 at 11.48.41 AM.jpeg",
      desc: '',
      sub: ''
    },
    {
      src: "assets/images/Image 2022-05-08 at 11.48.33 AM.jpeg",
      desc: '',
      sub: ''
    },
    {
      src: "assets/images/Image 2022-05-08 at 11.48.17 AM.jpeg",
      desc: '',
      sub: ''
    },
    
    {
      src: "assets/images/Image 2022-05-08 at 11.44.08 AM.jpeg",
      desc: '',
      sub: ''
    },

    {
      src: "assets/images/Image 2022-05-08 at 11.43.32 AM.jpeg",
      desc: '',
      sub: ''
    },
    {
      src: "assets/images/Image 2022-05-08 at 11.43.14 AM.jpeg",
      desc: '',
      sub: ''
    },
  
    {
      src: "assets/images/Image 2022-05-08 at 11.42.39 AM.jpeg",
      desc: '',
      sub: ''
    },

    {
      src: "assets/images/Image 2022-05-08 at 11.36.16 AM.jpeg",
      desc: '',
      sub: ''
    },
 
    {
      src: "assets/images/Image 2022-05-08 at 11.32.49 AM.jpeg",
      desc: '',
      sub: ''
    },
    {
      src: "assets/images/Image 2022-05-08 at 11.32.26 AM.jpeg",
      desc: '',
      sub: ''
    },

    {
      src: "assets/images/Image 2022-05-08 at 11.31.36 AM.jpeg",
      desc: '',
      sub: ''
    },
    {
      src: "assets/images/Image 2022-05-08 at 11.31.08 AM.jpeg",
      desc: '',
      sub: ''
    },
    
    {
      src: "assets/images/Image 2022-05-08 at 11.30.50 AM.jpeg",
      desc: '',
      sub: ''
    },
    {
      src: "assets/images/Image 2022-05-08 at 11.30.32 AM.jpeg",
      desc: '',
      sub: ''
    },
    
    {
      src: "assets/images/Image 2022-05-08 at 11.30.14 AM.jpeg",
      desc: '',
      sub: ''
    },

    {
      src: "assets/images/Image 2022-05-08 at 11.29.58 AM.jpeg",
      desc: '',
      sub: ''
    },
    {
      src: "assets/images/Image 2022-05-08 at 11.29.43 AM.jpeg",
      desc: '',
      sub: ''
    },
    {
      src: "assets/images/Image 2022-05-08 at 11.28.37 AM.jpeg",
      desc: '',
      sub: ''
    },
    
    {
      src: "assets/images/Image 2022-05-08 at 11.28.14 AM.jpeg",
      desc: '',
      sub: ''
    },
    {
      src: "assets/images/Image 2022-05-08 at 11.26.42 AM.jpeg",
      desc: '',
      sub: ''
    },
    {
      src: "assets/images/Image 2022-05-08 at 11.25.45 AM.jpeg",
      desc: '',
      sub: ''
    },
    {
      src: "assets/images/Image 2022-05-08 at 11.25.16 AM.jpeg",
      desc: '',
      sub: ''
    },
    {
      src: "assets/images/Image 2022-05-08 at 11.25.02 AM.jpeg",
      desc: '',
      sub: ''
    },
    {
      src: "assets/images/Image 2022-05-08 at 11.24.38 AM.jpeg",
      desc: '',
      sub: ''
    },
    {
      src: "assets/images/Image 2022-05-08 at 11.24.21 AM.jpeg",
      desc: '',
      sub: ''
    },
    {
      src: "assets/images/Image 2022-05-08 at 11.24.03 AM.jpeg",
      desc: '',
      sub: ''
    },
    {
      src: "assets/images/Image 2022-05-08 at 11.23.47 AM.jpeg",
      desc: '',
      sub: ''
    },
    {
      src: "assets/images/Image 2022-05-08 at 11.23.33 AM.jpeg",
      desc: '',
      sub: ''
    },
    {
      src: "assets/images/Image 2022-05-09 at 2.21.41 PM.jpeg",
      desc: "multi-fuel fired 100tph water tube steam boiler",
      sub: "Arvind ltd Ahmedabad"
    }
  ]

  var settings = {
    columnCount:{
      default:5,
      mobile:3,
      tab:4
    },
    mode: 'dark'
  }

  return (
    <div className="App">
      <header className="App-header">
        <h1>Hi, I’m Prakash!</h1>
        <p>
          Civil Engineer from Vadodara, India. 
        </p>
        
        <a
          className="App-link"
          href="mailto:prakashkathrecha7@gmail.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          Contact me
        </a>


        <h6>find me on other platforms: <a href="https://www.facebook.com/prakash.kathrecha" rel="noreferrer" target="_blank">Facebook</a> &
        &nbsp;<a href="https://www.linkedin.com/in/prakash-kathrecha-46b637132" rel="noreferrer" target="_blank">LinkedIn</a>
        </h6>

      </header>

      <div className="App-content">
        <div className='card'>
          <h3>Personal Qualities</h3>
          <p>Technical knowledge, goal-oriented, team-leader,responsible</p>
        </div>

        <div className='card'>
          <h3>Technical Skills</h3>
          <span className="badge">Project management and execution</span>
          <span className="badge">QC</span>
          <span className="badge">Quantity surveying</span>
          <span className="badge">Billing</span>
          <span className="badge">Autocad</span>
        </div>

        <div className='card'>
          <h3>Experience</h3>

          <div className='card-experience'>
            <p className='txt-time'>1 year 6 months</p>

            <h6><b>Site engineer</b> @ Akshar builders group, Vadodara</h6>

            <p>Construction of Taxshila project of 4 apartments (gf + 5)</p>
          </div>

          <div className='card-experience'>
            <p className='txt-time'>1 year</p>

            <h6><b>Project engineer</b> @ M.K.C. Infrastructure ltd., Rajkot</h6>

            <p>Widening of existing road and structures of SH-25 rajkot-bhavnagar road</p>
          </div>

          <div className='card-experience'>
            <p className='txt-time'>1 year 6 months</p>

            <h6><b>Project engineer</b> @ Shree Khodiyar Construction, Morbi</h6>

            <p>Construction of ceramic tiles production units for the following companies :
            </p>

            <ul>
              <li>1. Simero International Llp</li>
              <li>2. Livolla Granito Llp</li>
              <li>3. Colortiles Pvt Ltd </li>
            </ul>
          </div>

          <div className='card-experience'>
            <p className='txt-time'>1 year 5 months</p>

            <h6><b>Sr.civil engineer</b> @ Bhumi Procon Pvt. Ltd.</h6>

            <p>construction of industrial shed at Arvind ltd. Santej(Gandhinagar) –
            </p>

            <ul>
              <li>1. Knits Processing Unit</li>
              <li>2. Knits Godown Shed </li>
            </ul>
          </div>

          <div className='card-experience'>
            <p className='txt-time'>from january 2020 to present</p>

            <h6><b>Head of civil department</b> @ Mayuge Sugar Industries Ltd</h6>

            <ul>
              <li>1.construction of 23MW powerplant project.</li>
              <li>2.construction of staff colony having floor area 5500 sqm , total 23 units including bachlors rooms,studio houses,1bhk houses and 2bhk houses at mayuge sugar industries ltd at sugar plant.</li>
              <li>3.construction of staff colony having floor area 900 sqm, 6 unit of 1bhk houses at mayuge sugar industries ltd , steel division.</li>
              <li>4.expansion of CCM shed at mayuge sugar industries ltd,steel division. </li>
              <li>5.expansion of boiling house ,sugar house,sugar godown.</li>
              <li>6.prefabricated shed having dimentions 105m * 50 m for bagass handling.</li>
              <li>7.construction of 100TCD sugar refinery.</li>
              <li>8.construction for expanding mill capacity from 4500TCD to 7000TCD.</li>
            </ul>
          </div>
        </div>

        <div className='card'>
          <h3>Vocational Training</h3>
          <p>Training in the project of C.M.T.L. Building (civil material testing laboratory) at Kanchwa Enterprise in Reliance industries ltd. Jamnagar.</p>
        </div>

        <div className='card'>
          <h3>Academic Project</h3>
          <p>A project on using scrubbed plastic and fiber (granular size) in production of concrete and tests on its strength variations , thermal behaviour, elasticity, and durability.</p>
        </div>

        <div className='card'>

          <h3>Education</h3>

          <div className='card-education'>
            <p>July 2015</p>
            <b>GEC, Bhuj- BE in Civil Engineering - 64% (6.4 CGPA)</b>
          </div>

          <div className='card-education'>
            <p>2011</p>
            <b>GSHSEB- 12th science – 65.13%</b>
          </div>

          <div className='card-education'>
            <p>2009</p>
            <b>GSHSEB- 10th- 86.46%</b>
          </div>
        </div>

        <div className='card'>

          <h3>Language known</h3>
          <p>English, Hindi, Gujarati</p>
        </div>

        <div className='card light-room-wrapper'>

          <h3>Gallery</h3>

          <Lightroom className="light-room" images={images} settings={settings} />

          <div className='clearfix'></div>
        </div>


        <div className='clearfix'></div>

      </div>
      
      <div className='clearfix'></div>
      
    </div >
  );
}

export default App;
